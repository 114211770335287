import $ from 'jquery'
import Rellax from 'rellax'

$(document).ready(function () {
  dhsv_vc_parallax_content_element()
  rellaxStartHandler()
})

const rellaxStartHandler = () => {
  let options = {
    rootMargin: '0px',
    threshold: 0,
  }

  let targets = document.querySelectorAll('.js-parallax-element .rellax')
  let observer = new IntersectionObserver(items => {
    items.forEach(el => {
      if (el.isIntersecting && !$(el.target).hasClass('observed')) {
        new Rellax(el.target, {
          center: true,
          breakpoints: [576, 768, 1201],
          callback: function () {
            $(el.target).addClass('observed')
          },
        })
      }
    })
  }, options)
  for (let i = 0; i < targets.length; i++) {
    observer.observe(targets[i])
  }
}

function dhsv_vc_parallax_content_element() {
  $('.dhsv_vc_parallax_content_element').each(function () {
    const contentElementBtmMargin = () => {
      const topEl = $('.js-top', this)
      const bottomEl = $('.js-bottom', this)
      const topElementHeight = topEl.outerHeight(true)
      const bottomElementHeight = bottomEl.outerHeight(true)
      let offsetBtmFromTop = 80
      if ($(window).width() < 576) {
        offsetBtmFromTop = 35
      }
      $(this).css(
        'margin-bottom',
        bottomElementHeight - topElementHeight + offsetBtmFromTop
      )
    }

    const columnsSlider = () => {
      $('.js-columns-slider', this)
        .not('.slick-initialized')
        .slick({
          dots: false,
          speed: 300,
          prevArrow: $('.js-slick-prev', this),
          nextArrow: $('.js-slick-next', this),
          responsive: [
            {
              breakpoint: 9999,
              settings: 'unslick',
            },
            {
              breakpoint: 1550,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        })
    }

    columnsSlider()
    contentElementBtmMargin()

    let resizeMaking
    $(window).resize(function () {
      clearTimeout(resizeMaking)
      resizeMaking = setTimeout(function () {
        columnsSlider()
        contentElementBtmMargin()
      }, 100)
    })
  })
}
