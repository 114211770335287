import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function () {
  dhsv_vc_invoice_teaser_slider()
})

function dhsv_vc_invoice_teaser_slider() {
  $('.dhsv_vc_invoice_teaser_slider ').each(function () {
    $('.js-invoice-slider', this).slick({
      dots: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.js-arrow-prev', this),
      nextArrow: $('.js-arrow-next', this),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    })
  })
}
