import $ from 'jquery'
import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui-touch-punch'

$(document).ready(function () {
  dhsv_vc_configurator()
})

function dhsv_vc_configurator() {
  $('.dhsv_vc_configurator').each(function () {
    let dragPositionEnd, dragPositionCenter, oneThirdLine
    const resultText = $('.js-result-text', this)
    const configuratorBottom = $(this).find('.js-configurator-bottom')
    let valuesSum = 0

    const configuratorCalculations = () => {
      dragPositionEnd = $('.js-drag-line', this).width() - 5
      dragPositionCenter = dragPositionEnd / 2 + 2.5
      oneThirdLine = dragPositionEnd / 3
    }

    configuratorCalculations()
    let resizeMaking
    $(window).resize(() => {
      clearTimeout(resizeMaking)
      resizeMaking = setTimeout(() => {
        configuratorCalculations()
        $('.js-drag-btn', this).each(function () {
          $(this).css('left', '50%')
        })
        $('.js-drag-item', this).each(function (index, el) {
          if (index === 1 || index === 4 || index === 7 || index === 10) {
            $(el).addClass('active')
          } else {
            $(el).removeClass('active')
          }
        })
      }, 100)
    })

    $('.js-drag-btn', this).draggable({
      axis: 'x',
      drag: function (event, ui) {
        const leftPosition = ui.position.left
        if (leftPosition < 5) {
          ui.position.left = 5
        }
        if (leftPosition > dragPositionEnd) {
          ui.position.left = dragPositionEnd
        }
      },
      stop: function (event, ui) {
        const leftPosition = ui.position.left
        if (leftPosition < oneThirdLine) {
          const thisDragItem = $(this).closest('.js-drag').find('.js-drag-item')
          $(this).animate(
            {
              left: 5,
            },
            500
          )
          thisDragItem.removeClass('active')
          $(thisDragItem[0]).addClass('active')
        } else if (
          leftPosition >= oneThirdLine &&
          leftPosition <= oneThirdLine * 2
        ) {
          const thisDragItem = $(this).closest('.js-drag').find('.js-drag-item')
          $(this).animate(
            {
              left: dragPositionCenter,
            },
            500
          )
          thisDragItem.removeClass('active')
          $(thisDragItem[1]).addClass('active')
        } else {
          const thisDragItem = $(this).closest('.js-drag').find('.js-drag-item')
          $(this).animate(
            {
              left: dragPositionEnd,
            },
            500
          )
          thisDragItem.removeClass('active')
          $(thisDragItem[2]).addClass('active')
        }
      },
    })
    $('.js-configurator-btn', this).click(() => {
      valuesSum = 0
      resultText.removeClass('active')
      $('.js-drag-item.active').each(function () {
        const currentValue = $(this).data('point')
        valuesSum = valuesSum + currentValue
      })

      resultText.filter(function () {
        if (
          $(this).data('show-start') <= valuesSum &&
          $(this).data('show-end') > valuesSum
        ) {
          $(this).addClass('active')
        }
      })

      configuratorBottom.slideDown()

      $('html, body').animate(
        {
          scrollTop: configuratorBottom.offset().top - 75,
        },
        1000
      )
    })
  })
}
