import $ from 'jquery'
import 'slick-carousel'

$(document).ready(() => {
  dhsv_vc_graphic()
})

function dhsv_vc_graphic() {
  const removeActiveClass = thisNode => {
    $(thisNode)
      .closest('.js-graphic')
      .find('.js-square, .js-small-nav')
      .removeClass('active')
  }

  const clickGraphicFunc = (clickedNode, otherGraphicNode) => {
    $(clickedNode, this).click(function () {
      removeActiveClass(this)
      const index = $(this).data('index')
      const graphic = $(this).closest('.js-graphic')
      $(this).addClass('active')
      graphic
        .find(`${otherGraphicNode}[data-index="${index}"]`)
        .addClass('active')
      graphic.find('.js-graphic-slider').slick('slickGoTo', +index - 1)
    })
  }

  const getCurrentSlideIndex = () => {
    $('.js-graphic-slider', this).on('afterChange', function (
      event,
      slick,
      currentSlide
    ) {
      removeActiveClass(this)
      const graphic = $(this).closest('.js-graphic')
      const index = currentSlide + 1
      graphic.find(`.js-square[data-index="${index}"]`).addClass('active')
      graphic.find(`.js-small-nav[data-index="${index}"]`).addClass('active')
    })
  }

  const accordionHandler = () => {
    $('.js-accordion .js-item-title, .js-accordion .js-item-content', this).on(
      'click',
      function () {
        const parent = $(this).closest('.js-item')
        if (parent.hasClass('active')) {
          $('.js-item').removeClass('active')
          $('.js-item-content').slideUp()
        } else {
          $('.js-item').removeClass('active')
          $('.js-item-content').slideUp()
          parent.addClass('active').find('.js-item-content').slideDown()
        }
      }
    )
  }

  $('.dhsv_vc_graphic').each(function () {
    $('.js-graphic-slider', this).slick({
      dots: false,
      infinite: false,
      prevArrow: $('.js-arrow-prev', this),
      nextArrow: $('.js-arrow-next', this),
      fade: true,
    })

    clickGraphicFunc('.js-square', '.js-small-nav')
    clickGraphicFunc('.js-small-nav', '.js-square')
    getCurrentSlideIndex()
    $('.js-square', this).click(function () {
      $(this)
        .closest('.js-graphic')
        .find('.js-graphic-items')
        .addClass('active')
    })

    $('.js-close', this).click(function () {
      $(this)
        .closest('.js-graphic')
        .find('.js-graphic-items')
        .removeClass('active')
    })

    accordionHandler()
  })
}
